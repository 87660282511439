* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Tajawal-Regular";
}
@font-face {
  font-family: "Tajawal-Regular";
  src: url("../Assets/Fonts/Tajawal/Tajawal-Regular.ttf");
}
@font-face {
  font-family: "Tajawal-Bold";
  src: url("../Assets/Fonts/Tajawal/Tajawal-Bold.ttf");
}
@font-face {
  font-family: "Tajawal-Medium";
  src: url("../Assets/Fonts/Tajawal/Tajawal-Medium.ttf");
}
body {
  background-color: rgba(26, 26, 26, 1);
}
.bannersml {
  color: white;
  font-family: "Tajawal-Regular";
  font-size: 20px;
  font-weight: 600;
}
.logoimg {
  width: 200px;
  height: 70px;
}
.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.fa-circle-check {
  background-color: rgb(24, 26, 24);
  padding: 2px;
  border-radius: 50%;
}
.modal-content {
  display: flex;
  align-items: center;
  border-radius: 20px;
  padding: 25px;
}
.share-btn button {
  background-color: rgb(39, 164, 54);
  padding: 1px 15px;
  color: white;
  border: 1px solid rgb(39, 164, 54);
  font-weight: 500;
  font-family: "Tajawal-Bold";
}
.successData p {
  font-size: 20px;
  font-family: "Tajawal-Bold";
}
.nav-button {
  border: none;
  padding: 13px 30px 13px 30px;
  background: linear-gradient(90deg, #e82539 0%, #9f1523 100%);
  color: white;
  font-family: "Tajawal-Bold";
  cursor: pointer;
  /* border-radius: 20px; */
  animation: blink-animation 3s infinite;
}
.nav-button:hover {
  animation: none;
}

@keyframes blink-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.banner {
  padding-top: 35px;
}
.validationmsg {
  color: rgb(250, 15, 15);
  /* font-weight: 700; */
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.bannerrght h2 {
  font-family: "Tajawal-Bold";
  color: white;
  font-size: 60px;
  line-height: 58px;
  /* font-weight: 700; */
}
.bannerrght {
  position: relative;
}
.banbulb {
  position: absolute;
  top: -35%;
  width: auto !important;
  height: 400px;
  z-index: -1;
  left: -40%;
}
.bannerrght p {
  color: white;
  font-family: "Tajawal-Regular";
  font-size: 20px;
  padding-bottom: 50px;
  padding-top: 30px;
}
.person-img-sec {
  margin-left: 0px !important;
}
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
  display: none;
}
/* .banbtn {
  border: 1px solid #e82539;
  padding: 8px 20px;
  background: linear-gradient(90deg, #e82539 0%, #9f1523 100%);
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: white;
  font-family: "Tajawal-Bold";
  cursor: pointer;
  font-size: 20px;
  z-index: 100;
}
.banbtn:hover {
  background: white;
  color: black;
  border: 1px solid black;
} */

.banbtn {
  background: linear-gradient(90deg, #e82539 0%, #9f1523 100%);
  padding: 6px 20px 6px 20px;
  border-radius: 25px;
  border: none;
  user-select: none;
  cursor: pointer;
  margin-left: 150px;
  font-weight: 700;
  font-size: 20px;
  transition: all 100ms linear;
  color: white;
  position: relative;
  animation: blink-animations 3s infinite;
  box-shadow: inset 0 4px 6px -1px rgb(0 0 0 / 0.1),
    0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.banbtn:active {
  box-shadow: inset 0 0 0 4px #043b3f6b;
}

.banbtn::before {
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #e82539 0%, #9f1523 100%);
  position: absolute;
  border-radius: inherit;
  z-index: -1;
  top: 0;
  left: 0;
  transition: all 500ms ease-in-out;
  opacity: 0;
  animation: flash 100ms ease-in-out;
  transform: scaleX(2) scaleY(2);
}

.banbtn:hover::before {
  opacity: 1;
  transform: scaleX(1) scaleY(1);
}
.banbtn:hover {
  animation: none;
}
@keyframes flash {
  0% {
    opacity: 1;
    transform: translate(45px);
  }
  100% {
    opacity: 0;
    transform: translate(0);
  }
}
.deliverbtn {
  border: 1px solid #e82539;
  padding: 8px 30px 8px 30px;
  background: linear-gradient(90deg, #e82539 0%, #9f1523 100%);
  margin-top: 15px;
  color: white;
  font-family: "Tajawal-Bold";
  cursor: pointer;
  margin-top: 12px;
  position: relative;
  z-index: 100;
}
.deliverbtn:hover {
  background: white;
  color: black;
  border: 1px solid black;
}
.whybanbtn {
  border: 1px solid #e82539;
  padding: 8px 30px 8px 30px;
  background: linear-gradient(90deg, #e82539 0%, #9f1523 100%);
  margin-top: 15px;
  color: white;
  font-family: "Tajawal-Bold";
  cursor: pointer;
  margin-top: 7px;
  position: relative;
  z-index: 100;
}
.whybanbtn:hover {
  background: white;
  color: black;
  border: 1px solid black;
}
.eight {
  font-size: 18px !important;
  color: #e82539 !important;
  font-weight: 500 !important;
}
@keyframes blink-animations {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fa-hand-pointer {
  transform: rotate(90deg);
  /* animation: blink-animations 2s infinite; */
}
/*  */
.banner-rght {
  border: 1px solid rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 34px;
}
.banner-rght h3 {
  color: white;
  font-size: 32px;
  font-family: "Tajawal-Bold";
}
.banner-rght input {
  border: 1px solid rgba(255, 255, 255, 1);
  background-color: rgba(26, 26, 26, 1);
  padding: 13px 10px 13px 15px;
  font-family: "Tajawal-Regular";
  color: white;
}
.banner-rght input::placeholder {
  color: rgba(255, 255, 255, 1);
}
.banner-rght select {
  border: 1px solid rgba(255, 255, 255, 1);
  background-color: rgba(26, 26, 26, 1);
  padding: 13px 10px 13px 15px;
  font-family: "Tajawal-Regular";
  color: rgba(255, 255, 255, 1);
}
.dropdown option {
  background-color: rgba(26, 26, 26, 1);
  color: rgba(255, 255, 255, 1);
  font-weight: 600;
}
.sign-up {
  background: linear-gradient(90deg, #e82539 0%, #a31624 100%);
  font-size: 18px;
  text-align: center;
  color: white;
  width: 100%;
  border: 1px solid #e82539;
  padding: 8px 30px;
  font-family: "Tajawal-Medium";
}
.sign-up:hover {
  background: white;
  color: black;
  border: 1px solid black;
}
.why {
  margin-top: 110px;
}

.whyquanzti {
  padding-top: 100px;
  position: relative;
}
.whyquanzti img {
  width: auto;
  height: 188%;
  position: absolute;
  z-index: -1;
  top: -60%;
  right: 35%;
}
.whyquanzti h1 {
  font-family: "Tajawal-Bold";
  color: white;
  font-size: 41px;
  /* font-weight: 700; */
}
.whyquanzti span {
  font-family: "Tajawal-Bold";
  color: rgba(232, 37, 57, 1);
  font-size: 47px;
  /* font-weight: 700; */
}
.whyquanzti p {
  color: white;
  font-family: "Tajawal-Regular";
  font-size: 18px;
}
.whyqbox img {
  width: 100%;
  height: auto;
}
.ourproject span {
  font-family: "Tajawal-Bold";
  color: rgba(232, 37, 57, 1);
}

.ourproject h1 {
  font-family: "Tajawal-Bold";
  color: white;
  font-size: 60px;
  text-align: center;
  /* font-weight: 700; */
}
.icon {
  color: red;
}
.ourproject p {
  color: white;
  font-family: "Tajawal-Regular";
  font-size: 20px;
  /* font-weight: 700; */
}
.ourproject {
  margin-top: 40px;
  position: relative;
}
.projheadlogo1 img {
  position: absolute;
  width: auto;
  height: auto;
  z-index: -1;
  right: 0;
  top: -230px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.whitespace img {
  position: absolute;
  width: 540px;
  height: 380px;
}
.projectimg img {
  width: 100%;
  height: 100%;
}
.ourservice-flex {
  display: flex;
}
/* .projectbox {
  margin-top: 100px;
} */
.whyowlcont span img {
  background: linear-gradient(180deg, #e82539 0%, #a11523 100%);
  color: white;
  width: 50px !important;
  height: auto;
  padding: 10px;
  border-radius: 5px;
  margin-right: 10px;
}
.owl {
  padding-top: 50px;
  padding-bottom: 90px;
}
.whyowlcont p {
  color: white;
  /* font-weight: 700; */
  padding: 11px 25px;

  border: 1px solid white;
  border-radius: 5px;
  text-wrap: nowrap;
}

.owl-2 .owl-carousel.owl-drag .owl-item {
  width: max-content !important;
}
.proj-box-cont h4 {
  color: white;
  font-family: "Tajawal-Bold";
}
.proj-box-cont h5 {
  color: white;
  font-family: "Tajawal-Bold";
}
/* .navbar {
  overflow: hidden;
  position: fixed;
  width: 100%;
  z-index: 1000;
  background-color: rgb(21, 21, 21);
} */
.proj-box-cont p {
  color: white;
  font-family: "Tajawal-Regular";
  height: 20%;
  text-align: justify;
}

.deli-exce-left h1 {
  font-family: "Tajawal-Bold";
  color: white;
  font-size: 39px;
}
.deli-exce-left p {
  color: white;
  font-family: "Tajawal-Regular";
  font-size: 20px;
  /* font-weight: 700; */
}
.ourservice {
  padding-bottom: 34px;
}
.deliver {
  padding: 55px 0px;
  background-color: rgb(59, 59, 59);
  border: 1px solid rgba(50, 50, 50, 1);
  position: relative;
  margin-top: 170px;
  margin-bottom: 125px;
}
.fa-circle-check {
  color: white;
  margin-top: 3px;
  margin-right: 4px;
}
.tech-stack {
  flex-wrap: wrap;
}
.tech-stack p {
  padding: 3px 20px;
  border: 1px solid white;
  margin-right: 15px;
  border-radius: 20px;
  text-wrap: nowrap;
  margin-bottom: 9px;
}
/* .tech-last {
  margin-top: 5px;
} */
.services {
  flex-wrap: wrap;
}
.services p {
  color: white;
  font-family: "Tajawal-Regular";
  margin-right: 30px;
  text-wrap: wrap;
}
.view-all {
  border: 1px solid #e82539;
  padding: 8px 30px 8px 30px;
  background: linear-gradient(90deg, #e82539 0%, #9f1523 100%);
  color: white;
  font-family: "Tajawal-Bold";
  cursor: pointer;
  margin-top: 40px;
}
.view-all:hover {
  background: white;
  color: black;
  border: 1px solid black;
}

.deli-exc-img {
  bottom: 13%;
  width: auto;
  height: 380px;
  position: absolute;
  right: 13%;
}
.ourservicehead span {
  font-family: "Tajawal-Bold";
  color: rgba(232, 37, 57, 1);
}
/* .service-topic img {
  width: 50px;
  height: auto;
  object-fit: contain;
  background: linear-gradient(180deg, #e82539 0%, #a11523 100%);
  padding: 10px;
  color: white;
  font-family: "Tajawal-Bold";
  font-size: 24px;
  text-align: center;
} */
.ourservicehead {
  position: relative;
}
.ourservicehead h1 {
  font-family: "Tajawal-Bold";
  color: white;
  font-size: 60px;
  text-align: center;
  /* font-weight: 700; */
}
.ourservice-head-logo img {
  position: absolute;
  width: auto;
  height: auto;
  left: 29%;
  bottom: -305%;
  z-index: -1;
  /* top: 0; */
}
/* .service-topic span {
  background: linear-gradient(180deg, #e82539 0%, #a11523 100%);
  padding: 6px 10px;
  color: white;
  font-family: "Tajawal-Bold";
  font-size: 24px;
  text-align: center;
} */
/* .service-topic h5 {
  color: white;
  font-family: "Tajawal-Bold";
  font-size: 24px;
  margin-left: 15px;
 
  margin-bottom: 3px;
} */
/* .ourservicedown {
  margin-top: 8%;
} */
/* .service-topic {
  border-bottom: 2px dotted white;
  padding-bottom: 15px;
  display: flex;
  align-items: center;
} */
.service-point p {
  font-size: 24px;
  color: white;
  border-bottom: 1px dashed rgba(144, 144, 144, 1);
  padding-bottom: 12px;
  border-bottom-width: 2px;
  text-wrap: nowrap;
  font-family: "Tajawal-Regular";
}

.service-topic-last img {
  width: 50px;
  height: auto;
  object-fit: contain;
  background: linear-gradient(180deg, #e82539 0%, #a11523 100%);
  padding: 10px;
  color: white;
  font-family: "Tajawal-Bold";
  font-size: 24px;
  text-align: center;
}
.service-topic-last h5 {
  color: white;
  font-family: "Tajawal-Bold";
  font-size: 24px;
  margin-left: 15px;
  /* margin-top: 9px; */
  margin-bottom: 3px;
}
.service-topic-last {
  display: flex;
  padding-top: 25px;
}
.service-topic-first img {
  width: 50px;
  height: auto;
  object-fit: contain;
  background: linear-gradient(180deg, #e82539 0%, #a11523 100%);
  padding: 10px;
  color: white;
  font-family: "Tajawal-Bold";
  font-size: 24px;
  text-align: center;
}
.service-topic-first h5 {
  color: white;
  font-family: "Tajawal-Bold";
  font-size: 21px;
  margin-left: 15px;
  /* margin-top: 9px; */
  margin-bottom: 0px;
}
.service-topic-first {
  display: flex;
  border-bottom: 2px dotted white;
  padding-bottom: 15px;
  padding-top: 35px;
  width: 350px;
}
.first-service {
  display: flex;
  align-items: center;
}

.service-para {
  color: white;
  font-size: 18px;
  font-family: "Tajawal-Regular";
  width: auto;
  height: 185px;
  text-align: justify;
}

.more-detail {
  font-family: "Tajawal-Bold";
  text-decoration: none;
  color: white;
  font-size: 20px;
}
.fa-arrow-up {
  transform: rotate(45deg);
}
.proj-box-cont a {
  text-decoration: none;
}
/* .testi-head h1 {
  font-family: "Tajawal-Bold";
  color: white;
  font-size: 60px;
}
.caro-icon p {
  color: white;
} */
.ourproc-head {
  position: relative;
}
.ourproc-head-logo img {
  position: absolute;
  z-index: -1;
  left: -345px;
  width: auto;
  height: auto;
  bottom: -311px;
}
.ourprocess {
  margin-top: 60px;
}
.ourproc-head h1 {
  font-family: "Tajawal-Bold";
  color: white;
  font-size: 60px;
  text-wrap: wrap;
}
.ourproc-head span {
  font-family: "Tajawal-Bold";
  color: rgba(232, 37, 57, 1);
}
.ourproc-head p {
  font-family: "Tajawal-Bold";
  color: white;
  font-size: 30px;
  margin-top: 15px;
}
.process-right {
  position: relative;
}
.line {
  position: absolute;
  height: 125%;
  left: 30px;
  bottom: -40px;
  z-index: -1;
}
.tabparaclr {
  font-size: 28px;
  color: white;
  font-family: "Tajawal-Medium";
  margin-right: 20px;
  padding: 4px 45px;
  border-radius: 40px;
  cursor: pointer;
  background: linear-gradient(90deg, #e82539 0%, #a11523 100%) !important;
  width: 200px;
  text-align: center;
}
.tabparanoclr {
  font-size: 28px;
  color: white;
  font-family: "Tajawal-Medium";
  margin-right: 20px;
  padding: 4px 45px;
  border-radius: 40px;
  cursor: pointer;
  background-color: rgba(40, 40, 40, 1);
  width: 200px;
  text-align: center;
}
.proc-rghtbox {
  margin-top: 50px;
}
.proc-rghtbox img {
  width: 62px;
  height: 62px;
  margin-right: 30px;
}
.process-left {
  background-color: rgba(40, 40, 40, 1);
  padding: 40px 40px 30px 40px;
  border-radius: 15px;
}
.process-left h5 {
  font-family: "Tajawal-Bold";
  color: white;
  font-size: 30px;
}
.process-left p {
  color: white;
  font-size: 20px;
  /* text-align: justify; */
}
.bullet-icon {
  color: white;
  font-size: 20px;
}
.bullet-icon i {
  font-size: 20px;
}
.ourprocess-down {
  margin-top: 45px;
}
.testimonial {
  margin-top: 200px;
}
.testi-head-logo img {
  position: absolute;
  bottom: -260px;
  left: -302px;
  z-index: -1;
  width: auto;
  height: auto;
}
.testimo-img {
  position: relative;
}
.servicesubs h2 {
  font-family: "Tajawal-Bold";
  color: white;
  font-size: 30px;
  /* border-bottom: 1px solid red;
  width: 160px; */
}
.testi-head h1 {
  font-family: "Tajawal-Bold";
  color: white;
  font-size: 60px;
}
.testi-content {
  width: auto;
  height: 163px;
  overflow-y: auto;
}

.person-img-sec {
  /* border: 1px solid white;
  padding: 8px 20px; */
  margin-top: 20px;
}
.testi-content::-webkit-scrollbar {
  width: 3px;
}

/* Optional: customize scrollbar track */
.testi-content::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
.testi-content::-webkit-scrollbar-thumb {
  background: black;
}

/* Handle on hover */
.testi-content::-webkit-scrollbar-thumb:hover {
  background: black;
}

.caro-icon i {
  font-size: 30px;
  color: white;
  cursor: pointer;
}
.testi-person p {
  color: rgba(255, 255, 255, 1);
  font-family: "Tajawal-Regular";
}
.testicont p {
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  margin-bottom: 0px;
  padding-right: 20px;
  text-align: justify;
}
.testi-person h6 {
  color: white;
  font-family: "Tajawal-Bold";
  font-size: 22px;
  margin-bottom: 0px;
}
.person-img-sec p {
  font-size: 18px;
  color: rgb(214, 210, 210);
  font-family: "Tajawal-Regular";
}
/* .testi-person {
  padding-top: 10px;
} */
.owl {
  padding-top: 50px;
  padding-bottom: 90px;
}
.testi-person img {
  width: 100% !important;
  height: 70px;
  color: rgba(255, 255, 255, 0.65);
  font-size: 13px;
  padding-left: 30px;
}
.testicont {
  border-top: 1px solid black;
  border-right: 1px solid black;
  padding: 20px 30px 0px 20px;
  border-top-right-radius: 30px;
  border-width: 0.9px, 0.9px, 0px, 0px;
  border-color: rgba(255, 255, 255, 1);
}
.lets {
  background-color: rgba(40, 40, 40, 1);
  padding: 50px 0px 60px 0px;
}
.testi-logo {
  width: 100px !important;
  height: 70px;
}
.lets-head h1 {
  font-family: "Tajawal-Bold";
  color: white;
  font-size: 60px;
}
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  color: rgb(255, 255, 255);
  font-size: 55px;
  position: absolute;
  top: -145px;
  right: 0;
  bottom: -17px;
  font-weight: 700;
}
.owl-theme .owl-nav [class*="owl-"]:hover {
  background: none !important;
  color: #fff;
  text-decoration: none;
}
.lets-head p {
  font-family: "Tajawal-Regular";
  color: rgba(255, 255, 255, 0.6);
  font-size: 23px;
}
.letsstart-down {
  border-top: 2px solid rgba(91, 91, 91, 1);
  border-bottom: 2px solid rgba(91, 91, 91, 1);
}
.lets-para1 {
  font-family: "Tajawal-Bold";
  font-size: 40px;
  color: white;
  margin-bottom: 0px;
  font-weight: 700;
}

.lets-btn {
  padding: 8px 10px;
  background: linear-gradient(90deg, #e82539 0%, #9f1523 100%);
  margin-bottom: 0px !important;
  color: white !important;
  font-family: "Tajawal-Bold";
  font-weight: 700 !important;
  font-size: 15px !important;
  cursor: pointer;
}
.lets-btn:hover {
  color: black !important;
  background: white;
  font-size: 15px !important;
}
.lets-para2 {
  font-family: "Tajawal-Regular";
  font-size: 20px;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 0px;
}
.let-sec1 {
  border-right: 1px solid rgba(91, 91, 91, 1);
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
}
.let-sec3 {
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
}
.lets-sec-last {
  margin-top: 15px;
  margin-bottom: 15px;
}
.footers h3 {
  font-family: "Tajawal-Bold";
  color: white;
  font-size: 50px;
}
.footers span {
  font-family: "Tajawal-Bold";
  color: rgba(232, 37, 57, 1);
  font-size: 50px;
}
.footers p {
  font-size: 24px;
  color: rgba(255, 255, 255, 1);
}
.footer-logo {
  width: auto;
  height: 80px;
}
.footers {
  padding: 45px 0px 10px 0px;
}
.fter-last {
  border-top: 1px solid rgba(91, 91, 91, 1);
  border-bottom: 1px solid rgba(91, 91, 91, 1);
  padding: 35px 0px;
}
.foot-icons i {
  color: red;
  font-size: 25px;
}
.foot-icons a {
  color: white;
  font-size: 25px;
  text-decoration: none;
}
.footer-media img {
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.copyrght p {
  font-size: 20px;
  color: white;
  text-align: center;
}
.footer-media {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}
