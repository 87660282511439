@media screen and (max-width: 1200px) {
  .service-topic-first {
    display: flex;
    border-bottom: 2px dotted white;
    padding-bottom: 15px;
    padding-top: 35px;
    width: 275px !important;
  }
  .ourproc-head p {
    font-family: "Tajawal-Bold";
    color: white;
    font-size: 28px;
    margin-top: 15px;
    margin-left: 35px;
  }
}

@media screen and (max-width: 992px) {
  .banbulb {
    position: absolute;
    right: 63px;
    top: -47%;
    width: auto;
    height: auto;
    z-index: -1;
    display: none;
  }
  .whyquanzti img {
    width: 165%;
    height: 188%;
    position: absolute;
    z-index: -1;
    top: -62%;
    right: 37%;
    display: none;
  }
  .projheadlogo1 img {
    position: absolute;
    width: auto;
    height: auto;
    z-index: -1;
    right: 0;
    top: -230px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
  }
  .ourproc-head-logo img {
    position: absolute;
    z-index: -1;
    left: -345px;
    width: auto;
    height: auto;
    bottom: -271px;
    display: none;
  }
  .testi-head-logo img {
    position: absolute;
    bottom: -260px;
    left: -302px;
    z-index: -1;
    width: auto;
    height: auto;
    display: none;
  }
  .ourservice-head-logo img {
    position: absolute;
    width: auto;
    height: auto;
    left: 29%;
    bottom: -305%;
    z-index: -1;
    /* top: 0; */
    display: none;
  }
  .banner {
    padding-top: 20px;
  }
  .logoimg {
    width: 260px;
    height: 90px;
  }
  .banner-rght {
    border: 1px solid rgba(255, 255, 255, 1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 34px;
    margin-left: 0px !important;
    margin-top: 30px;
  }
  .why {
    margin-top: 60px;
  }
  .our-process {
    flex-direction: column;
  }
  .whyquanzti {
    padding-top: 20px;
    position: relative;
  }
  .owl {
    padding-top: 50px;
    padding-bottom: 0px;
  }
  .ourproject {
    margin-top: 0px;
    position: relative;
  }
  .project-con {
    padding-right: 0px !important;
  }
  .services p {
    color: white;
    font-family: "Tajawal-Regular";
    margin-right: 10px;
    text-wrap: wrap;
  }
  .deli-exc-img {
    bottom: 4%;
    width: 170px;
    height: 260px;
    position: absolute;
    right: 3%;
    display: none;
  }
  .deliver {
    padding: 40px 0px;
    background-color: rgb(59, 59, 59);
    border: 1px solid rgba(50, 50, 50, 1);
    position: relative;
    margin-top: 5%;
    margin-bottom: 5%;
  }
  .ourprocess {
    margin-top: 50px;
  }
  .process-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .process-right {
    position: relative;
  }
  .line {
    position: absolute;
    height: 92%;
    left: 200px;
    bottom: -6px;
    z-index: -1;
    display: none;
  }

  .ourprocess-down {
    margin-top: 0px;
  }
  .process-left {
    background-color: rgba(40, 40, 40, 1);
    padding: 35px 35px 5px 35px;
    border-radius: 15px;
    margin-top: 60px;
    margin-left: 0px !important;
  }
  .testimonial {
    margin-top: 70px;
  }
  .owl-theme .owl-nav {
    margin-top: 10px;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    color: rgb(255, 255, 255);
    font-size: 55px;
    position: absolute;
    top: -155px;
    right: 0;
    bottom: -17px;
    font-weight: 700;
  }
  .let-sec1 {
    border-right: none;
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
  }
  .lets-head {
    flex-direction: column;
  }
  .ourproc-head h1 {
    font-family: "Tajawal-Bold";
    color: white;
    font-size: 60px;
    text-wrap: nowrap;
    text-align: center;
  }
  .ourproc-head p {
    font-family: "Tajawal-Bold";
    color: white;
    font-size: 25px;
    margin-top: 15px;
    text-align: center;
  }
  .whyqbox img {
    width: 100%;
    height: auto;
    margin-top: 30px;
  }
  .tech-stack p {
    padding: 3px 20px;
    border: 1px solid white;
    margin-right: 7px;
    border-radius: 20px;
    text-wrap: nowrap;
    margin-bottom: 9px;
  }
  .ourservice-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
.end992 {
}
@media screen and (max-width: 767px) {
  .nav-button {
    border: 1px solid white;
    padding: 5px 10px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      #3b3b3b 25.5%,
      #1a1a1a 100%
    );
    color: white;
    font-family: "Tajawal-Bold";
    font-size: 15px;
  }
  .banbulb {
    position: absolute;
    right: 63px;
    top: -47%;
    width: auto;
    height: auto;
    z-index: -1;
    display: none;
  }
  .whyquanzti img {
    width: 165%;
    height: 188%;
    position: absolute;
    z-index: -1;
    top: -62%;
    right: 37%;
    display: none;
  }
  .projheadlogo1 img {
    position: absolute;
    width: auto;
    height: auto;
    z-index: -1;
    right: 0;
    top: -230px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
  }
  .ourproc-head-logo img {
    position: absolute;
    z-index: -1;
    left: -345px;
    width: auto;
    height: auto;
    bottom: -271px;
    display: none;
  }
  .testi-head-logo img {
    position: absolute;
    bottom: -260px;
    left: -302px;
    z-index: -1;
    width: auto;
    height: auto;
    display: none;
  }
  .ourservice-head-logo img {
    position: absolute;
    width: auto;
    height: auto;
    left: 29%;
    bottom: -305%;
    z-index: -1;
    /* top: 0; */
    display: none;
  }
  .ourproc-head p {
    font-family: "Tajawal-Bold";
    color: white;
    font-size: 13px;
    margin-top: 15px;
  }
  .deliver {
    padding: 40px 0px;
    background-color: rgb(59, 59, 59);
    border: 1px solid rgba(50, 50, 50, 1);
    position: relative;
    margin-top: 10%;
    margin-bottom: 5%;
  }
  .our-process {
    flex-direction: column !important;
  }
  .lets-head h1 {
    font-family: "Tajawal-Bold";
    color: white;
    font-size: 35px;
  }
  .lets-head {
    flex-direction: column;
  }
  .footers {
    flex-direction: column;
  }
  .fter-last {
    flex-direction: column;
  }
  .ourservice-flex {
    display: flex;
    flex-direction: column;
  }
  /* 
  .services p {
    padding: 4px 10px;
    border: 2px solid white;
    margin-right: 0px !important;
    border-radius: 20px;
    text-wrap: nowrap;
  } */
  .banner {
    padding-top: 0px;
  }
  .bannerrght h2 {
    font-family: "Tajawal-Bold";
    color: white;
    font-size: 27px;
    line-height: 32px;
  }
  .bannerrght p {
    color: white;
    font-family: "Tajawal-Regular";
    font-size: 12px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .banner-owl img {
    width: auto !important;
    height: 50px;
    /* object-fit: none; */
  }
  .banner-rght {
    border: 1px solid rgba(255, 255, 255, 1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 15px;
    margin-left: 0px !important;
    margin-top: 35px;
  }
  .banbtn {
    border: 1px solid #e82539;
    padding: 8px 30px 8px 30px;
    background: linear-gradient(90deg, #e82539 0%, #9f1523 100%);
    color: white;
    font-family: "Tajawal-Bold";
    cursor: pointer;
    margin-top: 5px;
    margin-left: 0px !important;
  }
  .whyquanzti {
    padding-top: 0px;
    position: relative;
  }
  .why {
    margin-top: 40px;
  }
  .whyquanzti h1 {
    font-family: "Tajawal-Bold";
    color: white;
    font-size: 35px;
  }
  .whyquanzti p {
    color: white;
    font-family: "Tajawal-Regular";
    font-size: 12px;
  }
  .whyqbox {
    margin-top: 35px;
  }
  .owl {
    padding-top: 30px;
    padding-bottom: 0px;
  }
  .ourproject h1 {
    font-family: "Tajawal-Bold";
    color: white;
    font-size: 45px;
    text-align: center;
  }
  .tech-stack p {
    padding: 3px 20px;
    border: 1px solid white;
    margin-right: 10px;
    border-radius: 20px;
    text-wrap: nowrap;
    margin-bottom: 9px;
  }

  .project-con {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .services p {
    color: white;
    font-family: "Tajawal-Regular";
    margin-right: 11px;
    text-wrap: nowrap;
  }

  .services {
    flex-wrap: wrap;
  }
  .deli-exc-img {
    bottom: 10%;
    width: 390px;
    height: 490px;
    position: absolute;
    right: 11%;
    display: none;
  }
  .deli-exce-left h1 {
    font-family: "Tajawal-Bold";
    color: white;
    font-size: 30px;
  }
  .deli-exce-left p {
    color: white;
    font-family: "Tajawal-Regular";
    font-size: 15px;
  }
  .ourservicehead h1 {
    font-family: "Tajawal-Bold";
    color: white;
    font-size: 45px;
    text-align: center;
    margin-top: 35px;
  }
  .process-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-left: 0px !important;
    position: relative !important;
  }

  .service-topic-first {
    display: flex;
    border-bottom: 2px dotted white;
    padding-bottom: 15px;
    padding-top: 10px;
    width: auto !important;
  }
  .ourproc-head h1 {
    font-family: "Tajawal-Bold";
    color: white;
    font-size: 45px;
    text-wrap: nowrap;
    text-align: center;
    margin-top: 30px;
  }
  .ourprocess {
    margin-top: 30px;
  }
  .foot-icons i {
    color: red;
    font-size: 18px;
  }
  .proc-rghtbox img {
    width: 50px;
    height: 50px;
    margin-right: 30px;
  }
  /* .deliver {
    overflow-y: hidden;
  }
  .ourservice {
    overflow-y: hidden;
  }
  .why {
    overflow-y: hidden;
  }
  .ourproj {
    overflow-y: hidden;
  } */
  .testi-person img {
    width: 100% !important;
    height: 70px;
    color: rgba(255, 255, 255, 0.65);
    font-size: 13px;
    padding-left: 0px;
  }
  .view-all {
    border: 1px solid #e82539;
    padding: 8px 30px 8px 30px;
    background: linear-gradient(90deg, #e82539 0%, #9f1523 100%);
    color: white;
    font-family: "Tajawal-Bold";
    cursor: pointer;
    margin-top: 23px;
  }

  .tabparaclr {
    font-size: 28px;
    color: white;
    font-family: "Tajawal-Medium";
    margin-right: 20px;
    padding: 4px 45px;
    border-radius: 40px;
    cursor: pointer;
    background: linear-gradient(90deg, #e82539 0%, #a11523 100%) !important;
    width: 200px;
    text-align: center;
  }
  .tabparanoclr {
    font-size: 28px;
    color: white;
    font-family: "Tajawal-Medium";
    margin-right: 20px;
    padding: 4px 45px;
    border-radius: 40px;
    cursor: pointer;
    background-color: rgba(40, 40, 40, 1);
    width: 200px;
    text-align: center;
  }
  .process-left h5 {
    font-family: "Tajawal-Bold";
    color: white;
    font-size: 23px;
  }
  .process-left p {
    color: white;
    font-size: 15px;
    text-align: justify;
    margin-bottom: 5px;
  }
  .proc-rghtbox {
    margin-top: 20px;
  }
  .process-left {
    background-color: rgba(40, 40, 40, 1);
    padding: 15px !important;
    border-radius: 15px;
    margin-left: 0px !important;
    margin-top: 50px;
  }
  .testimonial {
    margin-top: 30px;
  }
  .logoimg {
    width: 150px;
    height: 50px;
  }
  .ourproject {
    margin-top: 30px;
    position: relative;
  }
  .ourprocess-down {
    margin-top: 0px;
  }
  .testi-head h1 {
    font-family: "Tajawal-Bold";
    color: white;
    font-size: 45px;
    text-align: center;
  }
  .owl-theme .owl-nav {
    margin-top: 10px;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    color: rgb(255, 255, 255);
    font-size: 48px;
    position: absolute;
    top: -118px;
    right: 0;
    bottom: -17px;
    font-weight: 700;
  }
  .bullet-icon i {
    font-size: 15px;
  }
  .let-sec1 {
    border-right: 1px solid rgba(91, 91, 91, 1);
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
  }
  .lets-sec-last {
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
  }
  .lets-para1 {
    font-family: "Tajawal-Bold";
    font-size: 25px;
    color: white;
    margin-bottom: 0px;
  }
  .lets-para2 {
    font-family: "Tajawal-Regular";
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 0px;
  }
  .footers {
    padding: 10px 0px 20px 0px;
  }
  .lets {
    background-color: rgba(40, 40, 40, 1);
    padding: 50px 0px 20px 0px;
  }
  .footers h3 {
    font-family: "Tajawal-Bold";
    color: white;
    font-size: 40px;
  }
  .footers span {
    font-family: "Tajawal-Bold";
    color: rgba(232, 37, 57, 1);
    font-size: 40px;
  }
  .footers p {
    font-size: 16px;
    color: rgba(255, 255, 255, 1);
  }
  .footer-logo {
    width: 150px;
    height: auto;
    margin-top: 10px !important;
  }
  .foot-icons a {
    color: white;
    font-size: 16px;
    text-decoration: none;
    margin-top: 5px;
  }
  .footer-media img {
    width: 20px;
    height: auto;
    cursor: pointer;
    margin-top: 16px;
  }
  .copyrght p {
    font-size: 13px;
    color: white;
    text-align: center;
  }
}
